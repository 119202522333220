export const queryKeys = {
  user: "user",
  userSession: "userSession",
  bodyPart: "body-part",
  bodyParts: "body-parts",
  specialties: "specialties",
  specialty: "specialty",
  concerns: "concerns",
  treatments: "treatments",
  treatment: "treatment",
  blogs: "blogs",
  blogCategories: "blog-categories",
  dashboardMonthReportLastest: "dasboard-month-report-latest",
  dashboardMonthReportList: "dasboard-month-report-list",
};
